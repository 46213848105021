$bar-color: #eafade !default;
$bar-stroke: #8d99a6 !default;
$border-color: #b7b7bb !default;
$light-bg: #f7f8fc !default;
$light-border-color: #ebeff2 !default;
$light-yellow: #fcf8e3 !default;
$text-muted: #666 !default;
$text-light: #fff !default;
$text-color: #fff !default;
$blue: #72cb46 !default;
$handle-color: #ddd !default;
$bar-color-undefined: #dfe1eb !default;

.gantt {
    border-radius: 6px 6px 0px 0px;
    .grid-background {
        fill: none;
    }
    .grid-header {
        fill: #e00000;
        stroke: $border-color;
        stroke-width: 1.4;
    }
    .grid-row {
        fill: #ffffff;
    }
    .grid-row:nth-child(even) {
        fill: $light-bg;
    }
    .row-line {
        stroke: $light-border-color;
    }
    .tick {
        stroke: $border-color;
        stroke-width: 0.2;
        &.thick {
            stroke-width: 0.4;
        }
    }

    .tick-dasharray {
        stroke: $border-color;
        stroke-dasharray: 4;
        stroke-width: 0.2;
    }

    .tick-header {
        stroke: #dfe1eb;
        stroke-width: 0.2;
    }
    .today-highlight {
        fill: $light-yellow;
        opacity: 0.5;
    }

    .arrow {
        fill: none;
        stroke: $text-muted;
        stroke-width: 1.4;
    }

    .bar {
        fill: $bar-color;
        stroke: $bar-stroke;
        stroke-width: 0;
        transition: stroke-width 0.3s ease;
        user-select: none;
    }
    .bar.undefined {
        fill: $bar-color-undefined;
    }
    .bar-progress {
        fill: $blue;
    }
    .bar-invalid {
        fill: transparent;
        stroke: $bar-stroke;
        stroke-width: 1;
        stroke-dasharray: 5;

        & ~ .bar-label {
            fill: $text-light;
        }
    }
    .bar-label {
        fill: #252733;
        line-height: 36px;
        text-anchor: start;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 85%;

        &.big {
            fill: #252733;
            text-anchor: start;
        }
    }

    .handle {
        fill: $handle-color;
        cursor: ew-resize;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease;
    }

    .bar-wrapper {
        cursor: pointer;
        outline: none;

        &:hover {
            .bar {
                fill: darken($bar-color, 5);
            }
            .bar.undefined {
                fill: darken($bar-color-undefined, 5);
            }

            .bar-progress {
                fill: darken($blue, 5);
            }

            .handle {
                visibility: visible;
                opacity: 1;
            }
        }

        &.active {
            .bar {
                fill: darken($bar-color, 5);
            }

            .bar.undefined {
                fill: darken($bar-color-undefined, 5);
            }

            .bar-progress {
                fill: darken($blue, 5);
            }
        }
    }

    .lower-text,
    .upper-text,
    .names-text {
        fill: $text-color;
        font-size: 14px;
        line-height: 16px;
        font-family: Roboto;
        font-weight: bold;
        letter-spacing: 0.03em;
    }
    .upper-text {
        text-anchor: middle;
    }
    .lower-text {
        text-align: center;
        text-anchor: middle;
    }

    .hide {
        display: none;
    }
}

.gantt-container {
    position: relative;
    overflow: auto !important;
    font-size: 12px;
    border-radius: 6px 6px 0px 0px;

    .popup-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.8);
        padding: 0;
        color: #959da5;
        border-radius: 3px;
        max-width: 300px;

        .title {
            border-bottom: 3px solid $blue;
            padding: 10px;
        }

        .subtitle {
            padding: 10px;
            color: #dfe2e5;
        }

        .pointer {
            position: absolute;
            height: 5px;
            margin: 0 0 0 -5px;
            border: 5px solid transparent;
            border-top-color: rgba(0, 0, 0, 0.8);
        }
    }
}
